.jigool {
    position: absolute;
    left: -6px;
    bottom: -1px;
    transform: rotate(-173deg) scale(-1);
    z-index: 100;
}

.jigool path {
    fill: var(--LlightGray);
}

.blueJigool {
    position: absolute;
    right: -6px;
    bottom: -1px;
    transform: rotate(-184deg) scale(-1);
    z-index: 100;
}

.blueJigool path {
    fill: var(--LBlue);
}

.textMsgBase {
    align-self: flex-start;
    position: relative;
    display: inline-block;
    padding: 8px;
    /* width: calc( 100% - 32px ); */
    max-width: 600px;
    background-color: var(--LlightGray);
    border-radius: 18px;
    margin: 16px 16px 0 16px;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.04em;
}

.blueTextMsg {
    align-self: flex-end;
    background-color: var(--LBlue);
    color: var(--alwaysWhite);
    margin-right: 16px;
}


.knowledgeCard {
    align-self: flex-start;
    position: relative;
    display: inline-block;
    padding: 16px;
    background-color: var(--LlightGray);
    border-radius: 18px;
    margin: 16px 16px 0 16px;
    width: calc( 100% - 32px );
    max-width: 600px;
    /* min-height: 50vh; */
}
.knowledgeCard_title {
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
}
.knowledgeCard_subtitle {
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: var(--LGrayCarbon);
}
.knowledgeCard_doubleP {
    margin-top: 32px;
}
.knowledgeCard_doubleP-avatar {
    margin-right: 12px;
}
.knowledgeCard_description {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.05em;
    padding-top: 32px;
}
.knowledgeCard_infoTitle {
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: var(--LGrayShadow);
    font-size: 13px;
}
.knowledgeCard_download {
    background-color: var(--LWhite);
    padding: 4px 16px;
    border-radius: 100px;
    text-decoration: none;
    color: var(--LBlue);
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.04em;
    margin-left: auto;
}
.knowledgeCard_sepratorText {
    color: var(--LGrayCobalt);
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    margin: 8px 0;
}
a {
    text-decoration: none;
}
.item1 {
    margin: 8px -16px 0 0 ;
    border-top: 1px solid var(--LGrayAsh);
    padding: 8px 16px 0 0;
}
.item1_title {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: var(--LGrayCarbon);
}
.item1_description {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;    
    color: var(--LGrayCobalt);
}
.item1_description {
    text-decoration: none;
}


.item2 {
    padding-top: 16px;
}
.item2_img {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 16px 16px 0px 0px;
    background-color: var(--LWhite);
}

.item2 {
    text-decoration: none;
}
.item2_wrapper {
    margin: 0 -16px 0 0 ;
    padding: 16px 16px 0 0;
    border-top: 1px solid var(--LGrayAsh);
    flex-grow: 2;
}
.item2_title {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: var(--LWTH);
}
.item2_description {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: var(--LGrayCobalt);
}





.namecard {
    align-self: flex-start;
    position: relative;
    display: inline-block;
    padding: 16px;
    background-color: var(--LlightGray);
    border-radius: 18px;
    margin: 16px 16px 0 16px;
    min-height: 15vh;
}
.namecard_title {
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 16px;
}
.namecard_spelling {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.05em;
    padding-top: 16px;
    margin-bottom: 21px;
}
.namecard_grey {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: var(--LGrayShadow);
    padding-bottom: 2px;
}
.namecard_description {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.05em;
    padding-bottom: 16px;
}
.namecard_extraSection {
    border-top: 1px solid var(--LGrayAsh);
    margin-right: -16px;
    padding-right: 16px;
}


.showcase {
    align-self: flex-start;
    position: relative;
    display: inline-block;
    padding: 16px;
    background-color: var(--LlightGray);
    border-radius: 18px;
    margin: 16px 16px 0 16px;
}

.showcase_intro {
    margin: -16px;
    margin-bottom: 8px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: calc( 100% + 32px );
    min-height: 300px;
}

.showcase_title {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: var(--LBlack);
}


@media screen and (min-width: 1100px) {
    .showcase_intro {
        height: 450px;
    }
}