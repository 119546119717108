* {
  box-sizing: border-box;
}

:root {
  --distanceTop: min( 10vh, 100px );
  --distanceBottom: 10vh;
  --alwaysWhite: #fff;
  --scrollbarBG: transparent;
}


/* ? scrollbar  */
::-webkit-scrollbar {
  width: .2rem;
}
.footer_socials::-webkit-scrollbar {
  display: none;
}
.footer_socials::-webkit-scrollbar-thumb {
  height: 0px;
  width: 0px;
  opacity: 0px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--LWhite) var(--scrollbarBG);
}
::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  background-color: var(--LWhite) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}


@font-face {
  font-family: san-fran30sco;
  font-display: swap;
  src: url(../assets/font/SF-UI-Display-Regular.otf);
}

body {
  font-family: -apple-system, san-fran30sco, BlinkMacSystemFont, sans-serif;
  margin: 0;
  padding: 0;
  color: var(--LBlack);
  background-color: var(--LWhite);
  transition: color .2s;
}

.header {
  background-color: var(--LWhite);
  border-bottom: 1px solid var(--LlightGray);
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--distanceTop);
  min-height: 72px;
  z-index: 200;
}
.hamburger{
  position: absolute;
  left: 27px;
}
#nav-icon4 {
  width: 60px;
  height: 45px;
  position: absolute;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  transform: scale(.4, 0.4);
} 

#nav-icon4 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: var(--LGrayCobalt);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(1) {
  top: 0px;
}

#nav-icon4 span:nth-child(2) {
  top: 18px;
}

#nav-icon4 span:nth-child(3) {
  top: 36px;
}

#nav-icon4.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}




.mainWrapper {
  /* scroll-snap-type: y proximity; */
  height: calc( 100vh );
  width: 100%;
  overflow-y: scroll;
  padding-top: var(--distanceTop);
  padding-bottom: var(--distanceBottom);
}

.slide {
  scroll-snap-align: start;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
}

.slide:last-of-type{
  margin-bottom: 40vh !important;
}


.ts {
  align-self: center;
  text-align: center;
  font-size: 11px;
  letter-spacing: 5%;
  color: var(--LGrayCobalt);
  white-space: nowrap;
}


.footer {
  background-color: rgba(var(--LWhiteHex), 0.8);
  backdrop-filter: blur(32px);
  width: 100%;
  padding: 0 16px;
  position: fixed;
  bottom: 0;
  height: var(--distanceBottom);
  z-index: 100;
  display: flex;
  align-items: center;
}

.footer_email--dark path {
  fill: var(--LGrayCarbon);
}
.footer_email--light path {
  fill: var(--LGrayShadow);
}

.sendButton {
  border-radius: 18px;
  border: 1px solid var(--LGrayAsh);
  background-color: rgba( var(--LWhite), 0.2);
  color: var(--LBlack);
  width: calc( 100% - 18px );
  text-align: left;
  padding: 4px;
  padding-left: 16px;
  margin-left: 18px;
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 20px;
}

.sendArrowUp {
  margin-left: auto;
}

.footer_socials {
  display: flex;
  align-items: center;
  position: absolute;
  left: 100%;
  opacity: 0;
  overflow: auto;
  width: calc( 100% - 64px );
}

.footer_socials-item {
  margin: 0 8px;
}

.navContaiver {
  position: fixed;
  width: 100vw;
  transform: translateX(-100%);
  /* height: 100vh; */
  top: var(--distanceTop);
  bottom: 0;
  right: 0; 
  left: 0;
  overflow: auto;
  background-color: var(--LNavBack);
}

.navContaiver_links {
  padding: 0px 18px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
.navContaiver_links > a {
  text-decoration: none;
  margin-bottom: 24px;
  color: var(--LGrayCobalt);
}

.navSectionWrapper {
  border-radius: 16px;
  margin: 16px;
  background-color: var(--LNavItem);
  overflow: hidden;
}

.navItemWrapper {
  padding: 18px 16px;
  border-top: 1px solid var(--LWTH2);;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-decoration: none;
  color: var(--LBlack);
}
.navItemWrapper:first-child {
  border-color: transparent;
}


.round {
  border-radius: 50%;
}
.df {
  display: flex;
}
.jcc {
  justify-content: center;
}
.jcbw {
  justify-content: space-between;
}
.aic {
  align-items: center;
}
.fdr-c{
  flex-direction: column;
}
.fdr-cr{
  flex-direction: column-reverse;
}
.tac{
  text-align: center;
}
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}
.hovered:hover {
  filter: brightness(0.85);
  cursor: pointer;
}

@media screen and (min-width: 821px) {
  #email {
    margin-left: 18px;
  }
  #nav-icon4 {
    left: 16px;
  }
  .mainWrapper {
    top: var(--distanceTop);
    height: calc( 99vh - var(--distanceTop) );
    position: relative;
    margin: 0 auto;
  }
  .navContaiver {
    background-color: var(--LlightGray);
  }
  .navSectionWrapper{
    margin: 0px;
    border-radius: unset;
    background-color: var(--LlightGray);
  }
  .navItemWrapper {
    border: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    border-radius: 8px;
    margin: 0 16px;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
  }
  .navItemWrapper svg {
    margin-right: 12px;
  }
  .navItemWrapper path {
    fill: var(--LBlue);
  }
  .navItemWrapper:hover, .activeItem {
    background-color: var(--LGrayAsh);
  }
  .desktopNav_title {
    color: var(--LGrayShadow);
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.04em;
    margin: 32px 16px 8px;
  }
  .socialItem {
    color: var(--LBlack);
    display: flex;
    align-items: center;
  }
  .socialItem > img {
    margin-right: 12px;
  }
}

/* ? same as utils.js */
@media screen and (min-width: 821px) {
  :root{
    --width: 45%;
    --pad: calc( ( 100% - var(--width) ) / 2);
  }
  .mainWrapper {
    padding: 0 0 0 var(--pad);
  }
  .footer {
    padding: 0 0 0 var(--pad);
  }
  .navContaiver {
    width: var(--pad);
  }
  #nav-icon4 {
    /* visibility: hidden; */
  }
  .navItemWrapper {
    padding-right: 0;
  }
}

@media screen and (min-width: 1100px) {
  :root{
    --width: min( 1000px, 60% );
    --pad: calc( ( 100% - var(--width) ) / 2);
  }
  .mainWrapper {
    padding: 0 var(--pad);
  }
  .footer {
    padding: 0 var(--pad);
  }
  .navContaiver {
    width: var(--pad);
  }
  #nav-icon4 {
    /* visibility: visible; */
  }
}

@media screen and (min-width: 2000px) {
  :root{
    --width: 60%;
    --pad: min(450px, calc( ( 100% - var(--width) ) / 2))
  }
  .mainWrapper {
    /* max-width: var(--width); */
    padding: 0 var(--pad);
    top: var(--distanceTop);
    height: calc( 99vh - var(--distanceTop) );
    position: relative;
    margin: 0 auto;
  }
  .navContaiver {
    width: var(--pad);
  }
}